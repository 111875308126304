.area-div {
    position: absolute;
    top: 90px;
    left: 10px;
    z-index: 999;
    background-color: #fff;
    padding: 10px 0px;
    border-radius: 5px;
    width: 520px
}

.area-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.icon-wrapper {
    background-color: #6f9d2f;
    width: 40px;
    height: 40px;
    padding: 10px;
    border-radius: 50%;
    margin-right: 10px;
}

.area-title {
    padding: 5px 0px;
}

.area-label {
    font-size: 16px;
    color: #8d8d8d;
    padding: 0px 10px
}

.area-value {
    font-size: 18px;
    font-weight: 500;
    padding: 0px 10px
}

.task-add-btn {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 90px;
    z-index: 999;
}
