.mod-user-history {
    >.m-wrapper {
        >.m-headline {}

        >.m-content {
            row-gap: 32px;

            >.m-left {
                row-gap: 32px;

                >.m-image {
                    background-color: #d2ddc3;
                    border-radius: 10px;
                    position: relative;

                    >.m-headline {
                        position: absolute;
                        top: 20px;
                        left: 20px;

                        >h2,
                        >h3 {
                            text-shadow:
                                0px 0px 5px rgba(255, 255, 255, 1),
                                0px 0px 10px rgba(255, 255, 255, 1),
                                0px 0px 15px rgba(255, 255, 255, 1),
                                0px 0px 20px rgba(73, 255, 24, 1),
                                0px 0px 30px rgba(73, 255, 24, 1),
                                0px 0px 40px rgba(73, 255, 24, 1),
                                0px 0px 55px rgba(73, 255, 24, 1),
                                0px 0px 75px rgba(73, 255, 24, 1);
                        }
                    }
                }
            }

            .m-element {
                background-color: white;
                border-radius: 10px;
                margin: 24px 0;

                >.m-headline {
                    padding: 32px;
                }

                >canvas {
                    width: 100%;
                    height: auto;
                }
            }
        }

        >.m-right {
            margin: 24px 0;
        }

        >.m-line {}
    }
}