.graph-legend-icon-wrapper {
    border: 3px solid #c0c0c0;
    border-radius: 2px;
    padding: 2px;
    min-width: 70px;
}

.task-legend {
    cursor: pointer;
}

.graphIndex {
    width: 100%;
    height: 300px !important;
    /* padding-left: 30px; */
    /* padding: 50px; */
    overflow: visible;
    padding-top: 30px;
}