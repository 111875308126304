.Btn-locate {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 45px;
    height: 45px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    overflow: hidden;
    transition-duration: .3s;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
    background-color: #6f9d2f;
    z-index: 9999;
    top: 65%;
    right: 0.5%;
}

/* plus sign */
.sign-note {
    width: 100%;
    transition-duration: .3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sign-note svg path {
    fill: white;
}

/* text */
.text-note {
    position: absolute;
    right: 0%;
    width: 0%;
    opacity: 0;
    color: white;
    font-size: 15px;
    font-weight: 600;
    transition-duration: .3s;
}

/* hover effect on button width */
.Btn-note:hover {
    width: 125px;
    border-radius: 40px;
    transition-duration: .3s;
}

.Btn-note:hover .sign-note {
    width: 30%;
}

/* hover effect button's text */
.Btn-note:hover .text-note {
    opacity: 1;
    width: 70%;
    transition-duration: .3s;
    padding-right: 10px;
}

/* button click effect*/
.Btn-note:active {
    transform: translate(2px, 2px);
}