.dash-container {
    padding: 10px;
}

.m-buttons {
    column-gap: 10px;
}

.mod-dashboard {
    >.m-wrapper {
        column-gap: 10px;
        row-gap: 20px;
        flex-wrap: wrap;
        padding-top: 24px;

        >.m-line {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;


            >.m-right {
                text-align: center;

                >.m-title {
                    text-align: center;
                }
            }
        }




    }
}

.m-files-list {
    .card-box {
        padding: 20px;
        border-radius: 3px;
        margin-bottom: 30px;
        background-color: #fff;
    }

    .file-man-box {
        padding: 20px;
        border: 1px solid #e3eaef;
        border-radius: 5px;
        position: relative;
        margin-bottom: 20px
    }

    .file-man-box .file-close {
        color: #f1556c;
        position: absolute;
        line-height: 24px;
        font-size: 24px;
        right: 10px;
        top: 10px;
        visibility: hidden
    }

    .file-man-box .file-img-box {
        line-height: 120px;
        text-align: center
    }

    .file-man-box .file-img-box img {
        height: 64px
    }

    .file-man-box .file-download {
        font-size: 32px;
        color: #98a6ad;
        position: absolute;
        right: 10px
    }

    .file-man-box .file-download:hover {
        color: #313a46
    }

    // .file-man-box .file-man-title {
    //     padding-right: 25px
    // }

    .file-man-box:hover {
        -webkit-box-shadow: 0 0 24px 0 rgba(0, 0, 0, .06), 0 1px 0 0 rgba(0, 0, 0, .02);
        box-shadow: 0 0 24px 0 rgba(0, 0, 0, .06), 0 1px 0 0 rgba(0, 0, 0, .02)
    }

    .file-man-box:hover .file-close {
        visibility: visible
    }

    .text-overflow {
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        width: 100%;
        overflow: hidden;
        font-size: 15px;

    }

    a {
        font-size: 15px;
        text-decoration: none;
        color: #313a46;

        &:hover {
            text-decoration: underline;
        }
    }
}

.m-card {
    // max-height: 245px;
    background-color: white;
    border-radius: 13px;
    display: flex;
    flex-wrap: wrap;
    // height: max-content;
    justify-content: space-between;
    padding: 18px;
    align-items: center;
    // flex: 1;

    >.m-text {
        width: calc(100% - 48px);

        >.m-title {
            color: #74788d;
        }

        >.m-value {
            font-weight: bold;
            font-size: 20px;
        }

    }

    >.m-icon {
        width: 48px;

        >.m-image {}
    }

}

.m-profile {
    // background-color: white;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;

    >.m-header {
        width: 100%;
        max-height: 265px;
        display: flex;
        flex-wrap: wrap;
        background-color: #d2ddc3;
        justify-content: space-between;
        border-radius: 10px 10px 0 0;

        >.m-text {
            padding: 13px;
            color: #6f9d2f;
            max-width: 50%;

            >.m-headline {
                font-weight: bold;
            }

            >.m-subheadline {}
        }

        >.m-image {
            max-height: 100%;
            max-width: 50%;
            text-align: end;

            >img {
                max-height: 100%;
                max-width: 100%;

            }
        }
    }

    >.m-content {
        width: 100%;
        position: relative;
        min-height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;

        >.m-profile-image {
            position: absolute;
            bottom: 25px;
            left: 10px;
            max-width: 20%;

            >.m-image {
                max-width: 100%;
                height: auto;
                border-radius: 50%;
            }
        }

        >.m-name {
            font-weight: bold;
        }
    }
}

// @media only screen and (max-width: 992px) {
//     .mod-dashboard {

//     margin-top: 20px;
//     }
// }
.pl-0 {
    padding-left: 0px;
}

.pt-24 {
    padding-top: 24px;
}