.graphIndexSidebar {
    width: 100%;
    height: 200px;
    /* padding-left: 30px; */
    /* padding: 50px; */
    overflow: visible;
    /* padding-top: 30px; */
}

.graphIndex {
    width: 100%;
    height: 200px;
    /* padding-left: 30px; */
    /* padding: 50px; */
    overflow: visible;
    padding-top: 0px!important;
}

.svg-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 60px; */
    /* margin-bottom: 5rem !important; */
    /* margin-top: 10px !important; */
}

.grid .tick {
    stroke: lightgrey;
    opacity: 0.7;


}

.grid path {
    stroke-width: 0;
}

.x-axis-grid line {
    stroke: #def;
}

.y-axis-grid line {
    stroke: #def;
}

.txt1 {
    position: absolute;
    text-align: center;
    padding: .3rem;
    color: #313639;
    border: 0.5px solid #707070;
    background: #f9f9f9;
    border-radius: 3px;
    pointer-events: none;
    font-size: .7rem;
    z-index: 9999999999;
}

.graph_date_input {
    min-width: fit-content !important;
}

.graph_date_input .MuiIconButton-root {
    padding: 5px;
}


.myDot.patternAlertDot.ordinaryAlert{
    stroke: #FFA733;
    stroke-width: 3px;
    fill: transparent;
    opacity: .8 !important;
}

.myDot.patternAlertDot.severeAlert{
    stroke: #FF0000;
    stroke-width: 3px;
    fill: transparent;
    opacity: .8 !important;
}
.myDot1.selfpatternAlertDot.ordinaryAlert{
    stroke: #FFA733;
    stroke-width: 3px;
    fill: transparent;
    opacity: .8 !important;
}
.myDot1.selfpatternAlertDot.severeAlert{
    stroke: #FF0000;
    stroke-width: 3px;
    fill: transparent;
    opacity: .8 !important;
}

.forecastdot1.indexForecastAlertDot.ordinaryAlert{
    stroke: #FFA733;
    stroke-width: 6px;
    fill: #FFA733;
    opacity: .9 !important;
}

.forecastdot1.indexForecastAlertDot.severeAlert{
    stroke: #FF0000;
    stroke-width: 6px;
    fill: #FF0000;
    opacity: .9 !important;
}

