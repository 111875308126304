mod-sensor-data,
.mod-media-card {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
  rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  border-radius: 5px;
  height: 100%;
  max-height: calc(100% - 20px);

  .m-content {
    padding-bottom: 0;

    .m-headline {
      cursor: pointer;

      &:hover {
        color: #6f9d2f;
      }
    }

    .m-description {
      margin: 0;

      .m-title {
        padding-right: 5px;
      }
    }
  }
}
.task-link {
  text-decoration: none;
  color: #6f9d2f;
  position: relative;
  width: fit-content;
  padding: 2px 5px;
  text-transform: capitalize;
  font-size: 25px;
}
.task-link:hover {
  color: #89c534!important;
}

.task-link:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}
.link {
  text-decoration: none;
}
.task-card-img {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.task-card-img .content-overlay {
  background: rgba(111, 157, 47, 0.7);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -ms-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.task-card-img:hover .content-overlay {
  opacity: 1;
}
.task-card-img:hover .content-details {
  top: 50%;
  left: 50%;
  opacity: 1;
}
.content-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);


}
.task-card-img .content-details h3 {
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.15em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
}

.task-card-img .content-details p {
  color: #fff;
  font-size: 0.8em;
}

.fadeIn-bottom {
  top: 80%;
}
