.background-body {
  background-image: url("../images/login-background.png");
}

.slogon {
  font-size: 22px;
}

.btn-learn-more {
  border-color: #979797 !important;
  background-color: #ffffff !important;
  color: #444444 !important;
  border: 1px solid;
}

.btn-learn-more:hover {
  background-color: #d6d6d6 !important;
  color: #444444 !important;
}

.btn-learn-more:active {
  background-color: #d6d6d6 !important;
  color: #444444 !important;
}

.btn-learn-more:focus {
  background-color: #d6d6d6 !important;
  color: #444444 !important;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.btn-login {
  background-color: #6f9d2f !important;
  color: #ffffff;
  border: none !important;
}

.btn-login:hover {
  background-color: #618a27 !important;
  color: #ffffff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.btn-login:active {
  background-color: #618a27 !important;
  color: #ffffff !important;
}

.card-login {
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border: none;
}

.left-block-login {
  background-color: #f9fafb;
  /* padding-top: 15px;
    padding-bottom: 15px; */
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.right-block-login {
  padding-top: 15px;
  padding-bottom: 15px;
  /* align-self: center; */
  box-shadow: 0px 0px 20px 2px #e8e8e8;
  background-color: white;
}

/* @media screen and (min-width: 768px) {
  .right-block-login {
    height: 100%;
  }
} */

@media screen and (max-width: 767px) {
  .left-block-login {
    display: none !important;
  }

  .sign-in-container > .row > div:nth-child(2) {
    display: block !important;
  }


}

.signin-checkbox {
  font-size: 12px;
  display: flex;
  align-items: center;
}

.reset-pass-link {
  font-size: 12px;
  color: #1b3218;
  font-weight: 500;
}

.reset-pass-link:hover {
  color: #396b33;
}

.signup-p {
  font-size: 15px;
}

.label-signinCheckbox {
  font-weight: 500;
}

.signup-link {
  color: #1b3218;
  font-weight: 500;
}



a:hover {
  color: #4f9a44;
}
