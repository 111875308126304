.modal-header-box {
    background-color: #6f9d2f;
    padding: 20px;

    >.modal-title {
        color: #000000;
        background: #dfdfdf;
        padding: 3px 20px;
        border-radius: 3px;
    }
}
.modal-header-box-mobile {
    background-color: #6f9d2f;
    padding: 20px;
    width: 820px;

    >.modal-title {
        color: #000000;
        background: #dfdfdf;
        padding: 3px 20px;
        border-radius: 3px;
    }
}

@media only screen and (max-width: 768px) {
    .farming-practices-active {

        .nav-tabs {
            --bs-nav-tabs-border-width: var(--bs-border-width);
            --bs-nav-tabs-border-color: var(--bs-border-color);
            --bs-nav-tabs-border-radius: var(--bs-border-radius);
            --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
            --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
            --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
            --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
            border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
            width: 804px;
        }


    }
}

